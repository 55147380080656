<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div
          class="d-flex justify-center"
          style="width: 100%;"
        >
          <h4 style="font-size: 20px">
            Comissões nível júnior
          </h4>
        </div>

        <v-icon
          medium
          class="me-2"
          @click="closeModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <v-tabs
        v-model="tab"
        show-arrows
        class="mt-4"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
          class="tabs-just"
        >
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="actions">
            <v-tooltip
              v-if="saveOrEdit"
              left
              color="info"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="toggleSaveToEdit"
                >
                  <v-icon
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </template>

              <span>Editar</span>
            </v-tooltip>

            <v-tooltip
              v-else
              v-show="!loadingPop"
              left
              color="success"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="saveNewCommisions()"
                >
                  <v-icon
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiContentSaveOutline }}
                  </v-icon>
                </v-btn>
              </template>

              <span>Salvar</span>
            </v-tooltip>

            <v-progress-circular
              v-show="loadingPop"
              color="success"
              indeterminate
              size="10"
            >
            </v-progress-circular>
          </div>

          <div class="separator">
            <div
              class="label"
            >
              <span>Comissões Pop 110</span>
            </div>
          </div>

          <div class="section-inputs">
            <div class="container-commisions-pop">
              <div>
                <strong>Cotas Vendidas</strong>

                <div class="container-actions mt-4">
                  <span class="mr-4">De</span>

                  <input
                    v-model="dataEditable.junior_level_pop_goal"
                    :disabled="saveOrEdit"
                  >

                  <span
                    class="
                    ml-4
                    mr-4"
                  >ou +</span>
                </div>
              </div>

              <div>
                <strong>% de comissão</strong>

                <div class="container-actions  mt-4">
                  <input
                    v-model="formattedEliteLevelPopPercentage"
                    :disabled="saveOrEdit"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="actions">
            <v-tooltip
              v-if="saveOrEditOtherModels"
              left
              color="info"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="togleSaveOrEditOtherModels"
                >
                  <v-icon
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </template>

              <span>Editar</span>
            </v-tooltip>

            <v-tooltip
              v-else
              left
              color="success"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="saveNewCommissionOtherModels"
                >
                  <v-icon
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiContentSaveOutline }}
                  </v-icon>
                </v-btn>
              </template>

              <span>Salvar</span>
            </v-tooltip>
          </div>

          <div class="separator">
            <div
              class="label"
            >
              <span>Comissões demais modelos</span>
            </div>
          </div>

          <div class="section-inputs">
            <div class="container">
              <strong>Cotas Vendidas</strong>

              <div class="container-actions mt-4">
                <span class="mr-4">De</span>

                <input
                  v-model="dataEditable.junior_level_one_goal_min"
                  :disabled="saveOrEditOtherModels"
                />

                <span class="ml-4 mr-4">a</span>

                <input
                  v-model="dataEditable.junior_level_one_goal"
                  :disabled="saveOrEditOtherModels"
                />

                <span class="ml-4">cotas</span>
              </div>

              <div class="container-actions mt-4">
                <span class="mr-4">De</span>

                <input
                  v-model="dataEditable.junior_level_two_goal_min"
                  :disabled="saveOrEditOtherModels"
                />

                <span class="ml-4 mr-4">a</span>

                <input
                  v-model="dataEditable.junior_level_two_goal"
                  :disabled="saveOrEditOtherModels"
                />

                <span class="ml-4">cotas</span>
              </div>

              <div class="container-actions mt-4">
                <span class="mr-4">De</span>

                <input
                  v-model="dataEditable.junior_level_three_goal"
                  :disabled="saveOrEditOtherModels"
                />

                <span class="ml-4">ou +</span>
              </div>
            </div>

            <div class="container-commisions">
              <strong>% de comissão</strong>

              <div class="container-actions  mt-4">
                <input
                  v-model="dataEditable.junior_level_one_percentage"
                  :disabled="saveOrEditOtherModels"
                />
              </div>
              <div class="container-actions  mt-4">
                <input
                  v-model="dataEditable.junior_level_two_percentage"
                  :disabled="saveOrEditOtherModels"
                />
              </div>
              <div class="container-actions  mt-4">
                <input
                  v-model="dataEditable.junior_level_three_percentage"
                  :disabled="saveOrEditOtherModels"
                />
              </div>
            </div>

            <div class="container">
              <strong>Bônus</strong>

              <div class="container-actions">
                <v-checkbox
                  v-model="dataEditable.junior_level_one_second_parcel"
                  label="2° parc."
                  color="purple"
                  :disabled="saveOrEditOtherModels"
                />

                <v-checkbox
                  v-model="dataEditable.junior_level_one_fifth_parcel"
                  :label="'5° parc.'"
                  color="purple"
                  :disabled="saveOrEditOtherModels"
                />

                <v-checkbox
                  v-model="dataEditable.junior_level_one_delivery"
                  :label="'Entrega'"
                  color="purple"
                  :disabled="saveOrEditOtherModels"
                />
              </div>
              <div class="container-actions">
                <v-checkbox
                  v-model="dataEditable.junior_level_two_second_parcel"
                  :label="'2° parc.'"
                  color="purple"
                  :disabled="saveOrEditOtherModels"
                />

                <v-checkbox
                  v-model="dataEditable.junior_level_two_fifth_parcel"
                  :label="'5° parc.'"
                  :disabled="saveOrEditOtherModels"
                  color="purple"
                />

                <v-checkbox
                  v-model="dataEditable.junior_level_two_delivery"
                  :label="'Entrega'"
                  color="purple"
                  :disabled="saveOrEditOtherModels"
                />
              </div>
              <div class="container-actions">
                <v-checkbox
                  v-model="dataEditable.junior_level_three_second_parcel"
                  :label="'2° parc.'"
                  color="purple"
                  :disabled="saveOrEditOtherModels"
                />

                <v-checkbox
                  v-model="dataEditable.junior_level_three_fifth_parcel"
                  :label="'5° parc.'"
                  color="purple"
                  :disabled="saveOrEditOtherModels"
                />

                <v-checkbox
                  v-model="dataEditable.junior_level_three_delivery"
                  :label="'Entrega'"
                  color="purple"
                  :disabled="saveOrEditOtherModels"
                  class=""
                />
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiClose, mdiContentSaveOutline, mdiPencil } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    opened: Boolean,
    dataInfos: {
      type: Object,
      required: true,
    },
    idToEdit: {
      type: String,
      default: '',
      require: true,
    },
  },

  data() {
    return {
      loadingPop: false,
      commissionsData: [],
      dataEditable: { ...this.dataInfos },
      saveOrEdit: true,
      saveOrEditOtherModels: true,
      icons: {
        mdiClose,
        mdiPencil,
        mdiContentSaveOutline,
      },
      tab: '',
      tabs: [
        { title: '🛵 MODELO POP 110' },
        { title: '🏍 DEMAIS MODELOS' },
      ],
    }
  },

  computed: {
    formattedEliteLevelPopPercentage: {
      get() {
        return (this.dataEditable.junior_level_pop_percentage)
      },
      set(value) {
        this.dataEditable.junior_level_pop_percentage = (value)
      },
    },
  },

  watch: {
    //
  },

  methods: {
    toggleSaveToEdit() {
      this.saveOrEdit = !this.saveOrEdit
    },

    async saveNewCommisions() {
      this.loadingPop = true

      const bodyPop = {
        junior_level_pop_percentage: Number(this.dataEditable.junior_level_pop_percentage) / 100,
        junior_level_pop_goal: Number(this.dataEditable.junior_level_pop_goal),
      }

      try {
        await axiosIns.patch(`/api/v1/integration/commission_parameters_external_internal/update/${this.idToEdit}`, bodyPop)

        this.updatedTable()
      } catch (error) {
        console.log(error)
        this.showErrorMessage(error)
      } finally {
        this.loadingPop = false
        this.saveOrEdit = true
        this.closeModal()
      }
    },

    async saveNewCommissionOtherModels() {
      const body = {
        junior_level_one_goal_min: this.dataEditable.junior_level_one_goal_min,
        junior_level_one_goal: this.dataEditable.junior_level_one_goal,
        junior_level_two_goal_min: this.dataEditable.junior_level_two_goal_min,
        junior_level_two_goal: this.dataEditable.junior_level_two_goal,
        junior_level_three_goal: this.dataEditable.junior_level_three_goal,
        junior_level_one_percentage: Number(this.dataEditable.junior_level_one_percentage) / 100,
        junior_level_two_percentage: Number(this.dataEditable.junior_level_two_percentage) / 100,
        junior_level_three_percentage: Number(this.dataEditable.junior_level_three_percentage) / 100,
        junior_level_one_second_parcel: this.dataEditable.junior_level_one_second_parcel,
        junior_level_one_fifth_parcel: this.dataEditable.junior_level_one_fifth_parcel,
        junior_level_one_delivery: this.dataEditable.junior_level_one_delivery,
        junior_level_two_second_parcel: this.dataEditable.junior_level_two_second_parcel,
        junior_level_two_fifth_parcel: this.dataEditable.junior_level_two_fifth_parcel,
        junior_level_two_delivery: this.dataEditable.junior_level_two_delivery,
        junior_level_three_second_parcel: this.dataEditable.junior_level_three_second_parcel,
        junior_level_three_fifth_parcel: this.dataEditable.junior_level_three_fifth_parcel,
        junior_level_three_delivery: this.dataEditable.junior_level_three_delivery,
      }

      try {
        await axiosIns.patch(`/api/v1/integration/commission_parameters_external_internal/update/${this.idToEdit}`, body)
        this.updatedTable()
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.saveOrEditOtherModels = true
        this.closeModal()
      }
    },

    togleSaveOrEditOtherModels() {
      this.saveOrEditOtherModels = !this.saveOrEditOtherModels
    },

    async fetchData() {
      try {
        const response = await axiosIns.get('/api/v1/integration/commission_parameters_external_internal/index')
        // eslint-disable-next-line prefer-destructuring
        this.commissionsData = response.data.data[0]
      } catch (error) {
        console.error('Erro ao buscar dados:', error)
      }
    },
  },
}

</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tabs-just{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
  margin-bottom: 30px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.actions{
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.section-inputs{
  display: flex;
  width: 100%;
}

.section-inputs .container-commisions{
  margin: 12px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%
}

.section-inputs .container-commisions-pop{
  margin: 12px 20px 0px 20px;
  display: flex;
}

.container-actions{
  display: flex;
  align-items: center;
}

.container-actions input {
  padding: 12px 28px;
  width: 90px;
  border-radius: 5px;
  border: 0.5px solid var(--primrias-opacidade-50, rgba(231, 227, 252, 0.50));
  outline: none;
  color: #E7E3FC;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
