<template>
  <div>
    <v-card class="pl-16 pt-8 pb-8 pr-16 d-flex">
      <div class="card">
        <v-img
          :src="require(`@/assets/commission_junior.svg`)"
          height="25px"
          width="25px"
        ></v-img>

        <div class="card-infos">
          <span>JÚNIOR</span>
          <small>Motoca Motores</small>
        </div>
      </div>

      <v-spacer />

      <div
        class="icon-container"
        @click="openModal"
      >
        <v-tooltip
          left
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="25"
              class="mr-4"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiInformationVariant }}
            </v-icon>
          </template>

          <span>Regras estabelecidas para consultores internos
            e consultores de vendas online.</span>
        </v-tooltip>

        <v-icon size="25">
          {{ icons.mdiEye }}
        </v-icon>
      </div>
    </v-card>

    <v-dialog
      v-model="showModal"
      width="900px"
    >
      <ModalCommissionsJuniorsInternal
        :data-infos="commisionsJuniorsInternalData"
        :id-to-edit="idToEdit"
        @close="showModal = false"
        @updatedTable="updatedTable(), showModal = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountStarOutline, mdiAccountTieOutline, mdiEye, mdiInformationVariant,
} from '@mdi/js'
import ModalCommissionsJuniorsInternal from '../ModalCommissionsJuniorsInternal.vue'

export default {
  components: {
    ModalCommissionsJuniorsInternal,
  },
  data() {
    return {
      idToEdit: '',
      commisionsJuniorsInternalData: {},
      showModal: false,
      icons: {
        mdiAccountTieOutline,
        mdiEye,
        mdiAccountStarOutline,
        mdiInformationVariant,
      },
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    openModal() {
      this.showModal = true
    },

    async fetchData() {
      try {
        const response = await axiosIns.get('/api/v1/integration/commission_parameters_external_internal/index')
        this.idToEdit = response.data.data[0].id

        this.commisionsJuniorsInternalData = {
          junior_level_one_delivery_internal_consultant: response.data.data[0].junior_level_one_delivery_internal_consultant,
          junior_level_one_fifth_parcel_internal_consultant: response.data.data[0].junior_level_one_fifth_parcel_internal_consultant,
          junior_level_one_goal_internal_consultant: response.data.data[0].junior_level_one_goal_internal_consultant,
          junior_level_one_goal_min_internal_consultant: response.data.data[0].junior_level_one_goal_min_internal_consultant,
          junior_level_one_percentage_internal_consultant: response.data.data[0].junior_level_one_percentage_internal_consultant,
          junior_level_one_second_parcel_internal_consultant: response.data.data[0].junior_level_one_second_parcel_internal_consultant,
          junior_level_pop_goal_internal_consultant: response.data.data[0].junior_level_pop_goal_internal_consultant,
          junior_level_pop_percentage_internal_consultant: response.data.data[0].junior_level_pop_percentage_internal_consultant,
          junior_level_two_delivery_internal_consultant: response.data.data[0].junior_level_two_delivery_internal_consultant,
          junior_level_two_fifth_parcel_internal_consultant: response.data.data[0].junior_level_two_fifth_parcel_internal_consultant,
          junior_level_two_goal_internal_consultant: response.data.data[0].junior_level_two_goal_internal_consultant,
          junior_level_two_percentage_internal_consultant: response.data.data[0].junior_level_two_percentage_internal_consultant,
          junior_level_two_second_parcel_internal_consultant: response.data.data[0].junior_level_two_second_parcel_internal_consultant,
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error)
      }
    },
  },
}
</script>
<style scoped>
.card {
  display: flex;
  align-items: center;
}

.card-infos{
  display: flex;
  flex-direction: column;
  margin-left: 120px;
}

.card-infos span{
  color: #E7E3FC
}

.card-infos small{
  color: #E7E3FC
}

.icon-container{
  display: flex;
  align-items: center;
}
</style>
