import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"pl-16 pt-8 pb-8 pr-16 d-flex"},[_c('div',{staticClass:"card"},[_c(VImg,{attrs:{"src":require("@/assets/commission_junior.svg"),"height":"25px","width":"25px"}}),_c('div',{staticClass:"card-infos"},[_c('span',[_vm._v("JÚNIOR")]),_c('small',[_vm._v("Motoca Motores")])])],1),_c(VSpacer),_c('div',{staticClass:"icon-container",on:{"click":_vm.openModal}},[_c(VTooltip,{attrs:{"left":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-4",attrs:{"size":"25"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationVariant)+" ")])]}}])},[_c('span',[_vm._v("Regras estabelecidas para consultores internos e consultores de vendas online.")])]),_c(VIcon,{attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1)],1),_c(VDialog,{attrs:{"width":"900px"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('ModalCommissionsJuniorsInternal',{attrs:{"data-infos":_vm.commisionsJuniorsInternalData,"id-to-edit":_vm.idToEdit},on:{"close":function($event){_vm.showModal = false},"updatedTable":function($event){_vm.updatedTable(), _vm.showModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }