<template>
  <div>
    <v-card class="pl-16 pt-8 pb-8 pr-16 d-flex">
      <div class="card">
        <v-img
          :src="require(`@/assets/commission_junior.svg`)"
          height="25px"
          width="25px"
        ></v-img>

        <div class="card-infos">
          <span>JÚNIOR</span>
          <small>Motoca Motores</small>
        </div>
      </div>

      <v-spacer />

      <div
        class="icon-container"
        @click="openModal('juniors', true)"
      >
        <v-icon size="25">
          {{ icons.mdiEye }}
        </v-icon>
      </div>
    </v-card>

    <v-card class="pl-16 pt-8 pb-8 pr-16 d-flex mt-4">
      <div class="card">
        <v-img
          :src="require(`@/assets/commission_pleno.svg`)"
          height="28px"
          width="23px"
        ></v-img>

        <div class="card-infos">
          <span>PLENO</span>
          <small>Motoca Motores</small>
        </div>
      </div>

      <v-spacer />

      <div
        class="icon-container"
        @click="openModal('pleno', true)"
      >
        <v-icon size="25">
          {{ icons.mdiEye }}
        </v-icon>
      </div>
    </v-card>

    <v-card class="pl-16 pt-8 pb-8 pr-16 d-flex mt-4">
      <div class="card">
        <v-img
          :src="require(`@/assets/commission_senior.svg`)"
          height="28px"
          width="23px"
        ></v-img>

        <div class="card-infos">
          <span>SÊNIOR</span>
          <small>Motoca Motores</small>
        </div>
      </div>

      <v-spacer />

      <div
        class="icon-container"
        @click="openModal('senior', true)"
      >
        <v-icon size="25">
          {{ icons.mdiEye }}
        </v-icon>
      </div>
    </v-card>

    <v-card class="pl-16 pt-8 pb-8 pr-16 d-flex mt-4">
      <div class="card">
        <v-img
          :src="require(`@/assets/commission_elite.svg`)"
          height="23px"
          width="28px"
        ></v-img>

        <div class="card-infos">
          <span>ELITE</span>
          <small>Motoca Motores</small>
        </div>
      </div>

      <v-spacer />

      <div
        class="icon-container"
        @click="openModal('elite', true)"
      >
        <v-icon size="25">
          {{ icons.mdiEye }}
        </v-icon>
      </div>
    </v-card>
    <v-dialog
      v-model="showModalJunior"
      width="900px"
    >
      <ModalCommissionsJuniors
        :data-infos="commisionsJuniorsData"
        :id-to-edit="idToEdit"
        @close="openModal('juniors', false)"
        @updatedTable="updatedTable(), openModal('juniors', false)"
      />
    </v-dialog>

    <v-dialog
      v-model="showModalPleno"
      width="900px"
    >
      <ModalCommissionsPleno
        :data-infos="commisionsPlenoData"
        :id-to-edit="idToEdit"
        @close="openModal('pleno', false)"
        @updatedTable="updatedTable(), openModal('pleno', false)"
      />
    </v-dialog>

    <v-dialog
      v-model="showModalSenior"
      width="900px"
    >
      <ModalCommissionsSenior
        :data-infos="commisionsSeniorData"
        :id-to-edit="idToEdit"
        @close="openModal('senior', false)"
        @updatedTable="updatedTable(), openModal('senior', false)"
      />
    </v-dialog>

    <v-dialog
      v-model="showModalElite"
      width="900px"
    >
      <ModalCommissionsElite
        :data-infos="commisionsEliteData"
        :id-to-edit="idToEdit"
        @close="openModal('elite', false)"
        @updatedTable="updatedTable(), openModal('elite', false)"
      />
    </v-dialog>
  </div>
</template>
<script>

import axiosIns from '@/plugins/axios'
import { mdiAccountStarOutline, mdiAccountTieOutline, mdiEye } from '@mdi/js'
import ModalCommissionsElite from '../ModalCommissionsElite.vue'
import ModalCommissionsJuniors from '../ModalCommissionsJuniors.vue'
import ModalCommissionsPleno from '../ModalCommissionsPleno.vue'
import ModalCommissionsSenior from '../ModalCommissionsSenior.vue'

export default {
  components: {
    ModalCommissionsJuniors,
    ModalCommissionsPleno,
    ModalCommissionsSenior,
    ModalCommissionsElite,
  },
  data() {
    return {
      showModalSenior: false,
      showModalPleno: false,
      showModalElite: false,
      idToEdit: '',
      showModalJunior: false,
      commissionsData: [],
      commisionsJuniorsData: {},
      commisionsPlenoData: {},
      commisionsSeniorData: {},
      commisionsEliteData: {},
      icons: {
        mdiAccountTieOutline,
        mdiEye,
        mdiAccountStarOutline,
      },
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    openModal(type, isOpen) {
      const typeMap = {
        juniors: () => {
          this.showModalJunior = isOpen
        },
        pleno: () => {
          this.showModalPleno = isOpen
        },
        senior: () => {
          this.showModalSenior = isOpen
        },
        elite: () => {
          this.showModalElite = isOpen
        },
      }

      if (type in typeMap) {
        typeMap[type]()
      }
    },

    async fetchData() {
      try {
        const response = await axiosIns.get('/api/v1/integration/commission_parameters_external_internal/index')
        // eslint-disable-next-line prefer-destructuring
        this.commissionsData = response.data.data[0]

        this.idToEdit = response.data.data[0].id

        this.commisionsJuniorsData = {
          junior_level_pop_goal: response.data.data[0].junior_level_pop_goal,
          junior_level_pop_percentage: response.data.data[0].junior_level_pop_percentage,
          junior_level_one_goal_min: response.data.data[0].junior_level_one_goal_min,
          junior_level_one_goal: response.data.data[0].junior_level_one_goal,
          junior_level_two_goal_min: response.data.data[0].junior_level_two_goal_min,
          junior_level_two_goal: response.data.data[0].junior_level_two_goal,
          junior_level_three_goal: response.data.data[0].junior_level_three_goal,
          junior_level_one_percentage: response.data.data[0].junior_level_one_percentage,
          junior_level_two_percentage: response.data.data[0].junior_level_two_percentage,
          junior_level_three_percentage: response.data.data[0].junior_level_three_percentage,
          junior_level_one_second_parcel: response.data.data[0].junior_level_one_second_parcel,
          junior_level_one_fifth_parcel: response.data.data[0].junior_level_one_fifth_parcel,
          junior_level_one_delivery: response.data.data[0].junior_level_one_delivery,
          junior_level_two_second_parcel: response.data.data[0].junior_level_two_second_parcel,
          junior_level_two_fifth_parcel: response.data.data[0].junior_level_two_fifth_parcel,
          junior_level_two_delivery: response.data.data[0].junior_level_two_delivery,
          junior_level_three_second_parcel: response.data.data[0].junior_level_three_second_parcel,
          junior_level_three_fifth_parcel: response.data.data[0].junior_level_three_fifth_parcel,
          junior_level_three_delivery: response.data.data[0].junior_level_three_delivery,
        }

        this.commisionsPlenoData = {
          pleno_level_four_fifth_parcel: response.data.data[0].pleno_level_four_fifth_parcel,
          pleno_level_four_delivery: response.data.data[0].pleno_level_four_delivery,
          pleno_level_four_goal: response.data.data[0].pleno_level_four_goal,
          pleno_level_four_percentage: response.data.data[0].pleno_level_four_percentage,
          pleno_level_four_second_parcel: response.data.data[0].pleno_level_four_second_parcel,
          pleno_level_one_delivery: response.data.data[0].pleno_level_one_delivery,
          pleno_level_one_fifth_parcel: response.data.data[0].pleno_level_one_fifth_parcel,
          pleno_level_one_goal: response.data.data[0].pleno_level_one_goal,
          pleno_level_one_goal_min: response.data.data[0].pleno_level_one_goal_min,
          pleno_level_one_percentage: response.data.data[0].pleno_level_one_percentage,
          pleno_level_one_second_parcel: response.data.data[0].pleno_level_one_second_parcel,
          pleno_level_pop_goal: response.data.data[0].pleno_level_pop_goal,
          pleno_level_pop_percentage: response.data.data[0].pleno_level_pop_percentage,
          pleno_level_three_delivery: response.data.data[0].pleno_level_three_delivery,
          pleno_level_three_fifth_parcel: response.data.data[0].pleno_level_three_fifth_parcel,
          pleno_level_three_four_parcel: response.data.data[0].pleno_level_three_four_parcel,
          pleno_level_three_goal: response.data.data[0].pleno_level_three_goal,
          pleno_level_three_goal_min: response.data.data[0].pleno_level_three_goal_min,
          pleno_level_three_percentage: response.data.data[0].pleno_level_three_percentage,
          pleno_level_three_second_parcel: response.data.data[0].pleno_level_three_second_parcel,
          pleno_level_two_delivery: response.data.data[0].pleno_level_two_delivery,
          pleno_level_two_fifth_parcel: response.data.data[0].pleno_level_two_fifth_parcel,
          pleno_level_two_goal: response.data.data[0].pleno_level_two_goal,
          pleno_level_two_goal_min: response.data.data[0].pleno_level_two_goal_min,
          pleno_level_two_percentage: response.data.data[0].pleno_level_two_percentage,
          pleno_level_two_second_parcel: response.data.data[0].pleno_level_two_second_parcel,
        }

        this.commisionsSeniorData = {
          senior_level_four_goal_min: response.data.data[0].senior_level_four_goal_min,
          senior_level_four_fifth_parcel: response.data.data[0].senior_level_four_fifth_parcel,
          senior_level_four_delivery: response.data.data[0].pleno_level_four_delivery,
          senior_level_four_goal: response.data.data[0].senior_level_four_goal,
          senior_level_four_percentage: response.data.data[0].senior_level_four_percentage,
          senior_level_four_second_parcel: response.data.data[0].senior_level_four_second_parcel,
          senior_level_one_delivery: response.data.data[0].senior_level_one_delivery,
          senior_level_one_fifth_parcel: response.data.data[0].senior_level_one_fifth_parcel,
          senior_level_one_goal: response.data.data[0].senior_level_one_goal,
          senior_level_one_goal_min: response.data.data[0].senior_level_one_goal_min,
          senior_level_one_percentage: response.data.data[0].senior_level_one_percentage,
          senior_level_one_second_parcel: response.data.data[0].senior_level_one_second_parcel,
          senior_level_pop_goal: response.data.data[0].senior_level_pop_goal,
          senior_level_pop_percentage: response.data.data[0].senior_level_pop_percentage,
          senior_level_three_delivery: response.data.data[0].senior_level_three_delivery,
          senior_level_three_fifth_parcel: response.data.data[0].senior_level_three_fifth_parcel,
          senior_level_three_four_parcel: response.data.data[0].senior_level_three_four_parcel,
          senior_level_three_goal: response.data.data[0].senior_level_three_goal,
          senior_level_three_goal_min: response.data.data[0].senior_level_three_goal_min,
          senior_level_three_percentage: response.data.data[0].senior_level_three_percentage,
          senior_level_three_second_parcel: response.data.data[0].senior_level_three_second_parcel,
          senior_level_two_delivery: response.data.data[0].senior_level_two_delivery,
          senior_level_two_fifth_parcel: response.data.data[0].senior_level_two_fifth_parcel,
          senior_level_two_goal: response.data.data[0].senior_level_two_goal,
          senior_level_two_goal_min: response.data.data[0].senior_level_two_goal_min,
          senior_level_two_percentage: response.data.data[0].senior_level_two_percentage,
          senior_level_two_second_parcel: response.data.data[0].senior_level_two_second_parcel,
        }

        this.commisionsEliteData = {
          elite_level_four_goal_min: response.data.data[0].elite_level_four_goal_min,
          elite_level_four_fifth_parcel: response.data.data[0].elite_level_four_fifth_parcel,
          elite_level_four_delivery: response.data.data[0].elite_level_four_delivery,
          elite_level_four_goal: response.data.data[0].elite_level_four_goal,
          elite_level_four_percentage: response.data.data[0].elite_level_four_percentage,
          elite_level_four_second_parcel: response.data.data[0].elite_level_four_second_parcel,
          elite_level_one_delivery: response.data.data[0].elite_level_one_delivery,
          elite_level_one_fifth_parcel: response.data.data[0].elite_level_one_fifth_parcel,
          elite_level_one_goal: response.data.data[0].elite_level_one_goal,
          elite_level_one_goal_min: response.data.data[0].elite_level_one_goal_min,
          elite_level_one_percentage: response.data.data[0].elite_level_one_percentage,
          elite_level_one_second_parcel: response.data.data[0].elite_level_one_second_parcel,
          elite_level_pop_goal: response.data.data[0].elite_level_pop_goal,
          elite_level_pop_percentage: response.data.data[0].elite_level_pop_percentage,
          elite_level_three_delivery: response.data.data[0].elite_level_three_delivery,
          elite_level_three_fifth_parcel: response.data.data[0].elite_level_three_fifth_parcel,
          elite_level_three_four_parcel: response.data.data[0].elite_level_three_four_parcel,
          elite_level_three_goal: response.data.data[0].elite_level_three_goal,
          elite_level_three_goal_min: response.data.data[0].elite_level_three_goal_min,
          elite_level_three_percentage: response.data.data[0].elite_level_three_percentage,
          elite_level_three_second_parcel: response.data.data[0].elite_level_three_second_parcel,
          elite_level_two_delivery: response.data.data[0].elite_level_two_delivery,
          elite_level_two_fifth_parcel: response.data.data[0].elite_level_two_fifth_parcel,
          elite_level_two_goal: response.data.data[0].elite_level_two_goal,
          elite_level_two_goal_min: response.data.data[0].elite_level_two_goal_min,
          elite_level_two_percentage: response.data.data[0].elite_level_two_percentage,
          elite_level_two_second_parcel: response.data.data[0].elite_level_two_second_parcel,
          elite_level_five_delivery: response.data.data[0].elite_level_five_delivery,
          elite_level_five_fifth_parcel: response.data.data[0].elite_level_five_fifth_parcel,
          elite_level_five_goal: response.data.data[0].elite_level_five_goal,
          elite_level_five_percentage: response.data.data[0].elite_level_five_percentage,
          elite_level_five_second_parcel: response.data.data[0].elite_level_five_second_parcel,
          elite_minimum_annual_sales_average: response.data.data[0].elite_minimum_annual_sales_average,
          elite_maintain_sales_performance: response.data.data[0].elite_maintain_sales_performance,
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error)
      }
    },

    updatedTable() {
      this.fetchData()
    },
  },
}
</script>
<style scoped>
.card {
  display: flex;
  align-items: center;
}

.card-infos{
  display: flex;
  flex-direction: column;
  margin-left: 120px;
}

.card-infos span{
  color: #E7E3FC
}

.card-infos small{
  color: #E7E3FC
}

.icon-container{
  display: flex;
  align-items: center;
}
</style>
